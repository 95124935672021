

















































































































import Vue from "vue";
import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import { Platform } from "../../common/util/platform";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import notificationMixin from "../../mixin/notification";
import { Env } from "../../common/util/env";
import { PrintBit } from "../../model/entity/printJobSetting";

const api = new ShopAPI();
const translator = TranslateServiceBuilder.Instance()._;

export default Vue.extend({
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      isLoading: false,
      appVersion: Env.APP_VERSION,
    };
  },
  mounted() {
    api.set_token(store.state.token);
  },
  methods: {
    handleClickToggleSideMenu(e) {
      const className = e.target.className;
      if (className === "mb__admin-navarea") {
        this.$emit("toggleSideMenu");
      }
    },
    handleClick() {
      if (Platform.isMobile) {
        this.$emit("toggleSideMenu");
      }
    },
    handleClickLogout() {
      this.$emit('logout');
    },
    handleClickDrawer() {
      this.isLoading = true;
      let message = '';

      api
        .drawer_open({print_rule_id: store.state.printRule[PrintBit.TYPE_SETTLE_AND_RECEIPT]})
        .then((response) => {
          console.log(response.data);
          if (response.data.status !== "success") {
            message = translator("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          message =
            err.response.data.message ||
            translator("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    // canUseTakeout(): boolean {
    //   return store.getters.canUseTakeout;
    // },
    canUseEatIn(): boolean {
      return store.getters.canUseEatIn;
    },
    canUsePrinter(): boolean {
      return store.getters.canUsePrinter;
    },
    canUseOrderlist(): boolean {
      return store.state.shopSetting.is_orderlist;
    },
    staffId(): string {
      return store.state.staff.id;
    },
  },
});
